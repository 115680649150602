import React from 'react';
import { Breadcrumb, Button,message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import HairiButton from "../component/hairiButton";
import dow from '../image/dow.png'
import { useParams,Link } from 'react-router-dom';

const Download = () => {
    const items = [
        {
            title: (
                <>
                    <LeftOutlined />
                    <Link to="/">首页</Link>
                </>
            ),
        },
    ]
    const dowloadWinAction = () => {
        // window.location.href = "https://oss.zmrobo.com/mlblock/mlblock_1.0.28_win.exe";
        const link = document.createElement('a');
        link.href = "https://oss.zmrobo.com/mlblock/mlblock_1.0.30_win.exe";
        link.rel="noreferrer"
        link.download = "mlblock_1.0.30_win.exe";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const showModal = () => {
        const link = document.createElement('a');
        link.href = "https://oss.zmrobo.com/mlblock/linux/mlblock_1.0.28_amd64.deb";
        link.rel="noreferrer"
        link.download = "mlblock_1.0.28_amd64.deb";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <>
            <div
                style={{
                    width: '100%',
                    minWidth: "1200px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                    

                }}>
                <div
                    style={{
                        marginTop: "20px",
                        width: '1200px',
                    }}
                >
                    <Breadcrumb items={items} />
                </div>
            </div>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                marginTop: 20,
                marginBottom: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    width: '1200px',
                    minHeight: '230px',
                    marginTop: 80,
                    backgroundColor: "#F2FBFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'space-around'

                }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft: "40px", width: '150px', height: "150px", backgroundColor: "#43C5FF", borderRadius: "10px" }}>
                            <img src={dow} alt="" style={{width: '140px', height: "140px",}}/>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <span style={{  fontSize: '32px', width: "200px",height:"20px", }}>ML Block</span>
                            <br/>
                            <span>v1.0.30</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:"300px", marginRight: "40px" }}>
                        <div style={{marginRight:"20px"}}>
                            <HairiButton onClick={dowloadWinAction} buttonText="Windows" ></HairiButton>
                        </div>
                        {/* <div style={{marginRight:"20px"}}>
                            <HairiButton onClick={showModal} buttonText="麒麟系统" ></HairiButton>
                        </div> */}
                        <div style={{marginRight:"20px"}}>
                            <HairiButton onClick={showModal} buttonText="统信UOS" ></HairiButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Download;